import { defineStore, storeToRefs } from 'pinia';
import { useAppRoute } from '@/stores/app/route';
import { getQuarter } from '@/helpers/date';
import { useAppLoading } from '@/stores/app/loading';
import { useAppAlerts } from '@/stores/app/alerts';
import { useFilters } from '@/stores/filters/filters';
import { useDatePicker } from '@/stores/filters/datePicker';
import { format } from 'date-fns';
import { removeEmptyParams } from '@/stores/filters/helpers';
import { isEqual, isEmpty } from 'lodash-es';
import { ref, computed } from 'vue';
import { getDemographicSurveyReport, loadReqFillRate, getDemographicSurveyFilters } from '@/api/demographicSurveyReport';
import { combineDeclinedAndUnknown, combineOtherAndUnknownCategories } from '@/stores/insights/helpers';

const STAGES = ['review', 'screen', 'assessment', 'offer', 'hired'];

export const useDemographicSurveyReport = defineStore('demographicSurveyReport', () => {
  // Other Stores
  const { addAlert } = useAppAlerts();

  // State
  const genderBreakdown = ref({});
  const raceBreakdown = ref({});
  const genderByStage = ref({});
  const raceByStage = ref({});
  const reqFillRate = ref({});
  const sourceFillRate = ref([]);
  const reqFillRateLoadingMore = ref(false);
  const reqFillRateLoading = ref(false);
  const reqFillRateSort = ref('fill_rate');
  const isSurveyInsightsTab = ref(true);
  const isTableGenderData = ref(true);
  const optedToViewDataAnyway = ref(false);

  // Computed
  const isEmptyData = computed(() => {
    if (isSurveyInsightsTab.value) {
      return isEmpty(genderBreakdown.value)
        && isEmpty(raceBreakdown.value)
        && isEmpty(genderByStage.value)
        && isEmpty(raceByStage.value);
    }

    return isEmpty(reqFillRate.value) && isEmpty(sourceFillRate.value);
  });

  const genderAndRaceTableDatasetToUse = computed(() => {
    if (isTableGenderData.value) {
      return combineDeclinedAndUnknown(genderByStage.value, isTableGenderData.value);
    }

    return combineDeclinedAndUnknown(raceByStage.value, isTableGenderData.value);
  });

  const genderAndRaceTableTotals = computed(() => {
    const finalTotals = {
      review: 0,
      screen: 0,
      assessment: 0,
      offer: 0,
      hired: 0,
    };

    Object.keys(genderAndRaceTableDatasetToUse.value).forEach((group) => {
      STAGES.forEach((stage) => {
        finalTotals[stage] += genderAndRaceTableDatasetToUse.value[group][stage];
      });
    });

    Object.keys(finalTotals).forEach((stage) => {
      finalTotals[stage] = {
        value: finalTotals[stage],
        percent: stage !== 'hired' && finalTotals[stage] !== 0
          ? ((finalTotals[STAGES[STAGES.indexOf(stage) + 1]]
            / finalTotals[stage]) * 100).toFixed(1)
          : null,
      };
    });

    return finalTotals;
  });

  const labelMap = {
    male: 'Male',
    female: 'Female',
    genderUndefined: 'Undefined',
    asian: 'Asian',
    black: 'Black or African American',
    hispanic_latino: 'Hispanic or Latino',
    native_american: 'American Indian or Alaskan Native',
    two_or_more: 'Two or More Races',
    white: 'White',
    pacific_islander: 'Native Hawaiian or Pacific Islander',
    raceUndefined: 'Undefined',
  };

  const genderAndRaceTableRows = computed(() => {
    const finalDatasets = [];

    Object.keys(genderAndRaceTableDatasetToUse.value).forEach((key) => {
      const stageObj = {};

      Object.keys(genderAndRaceTableDatasetToUse.value[key]).forEach((stage) => {
        stageObj[stage] = {
          value: genderAndRaceTableDatasetToUse.value[key][stage],
          percent: stage !== 'hired' && genderAndRaceTableDatasetToUse.value[key][stage] !== 0
            ? ((genderAndRaceTableDatasetToUse.value[key][STAGES[STAGES.indexOf(stage) + 1]]
              / genderAndRaceTableDatasetToUse.value[key][stage]) * 100).toFixed(1)
            : null,
        };
      });

      finalDatasets.push({ label: labelMap[key], ...stageObj });
    });

    return finalDatasets;
  });

  const sortedSourceCategories = computed(() => combineOtherAndUnknownCategories(
    sourceFillRate.value,
  )
    .map((category) => {
      const sortedSources = category.sources.sort((a, b) => {
        if (b.fill_rate === a.fill_rate) {
          return b.total - a.total;
        }

        return a.fill_rate - b.fill_rate;
      });

      return { ...category, sources: sortedSources };
    })
    .sort((a, b) => {
      if (b.fill_rate === a.fill_rate) {
        return b.total - a.total;
      }

      return a.fill_rate - b.fill_rate;
    }));

  // Mutations
  const setData = ({
    newGenderBreakdown,
    newRaceBreakdown,
    newGenderByStage,
    newRaceByStage,
    newSourceFillRate,
  }) => {
    genderBreakdown.value = newGenderBreakdown;
    raceBreakdown.value = newRaceBreakdown;
    genderByStage.value = newGenderByStage;
    raceByStage.value = newRaceByStage;
    sourceFillRate.value = newSourceFillRate;
  };

  const updateReqFillRateSort = ({ sort, sortDirection }) => {
    reqFillRateSort.value = sortDirection === 'asc' ? `-${sort}` : sort;
  };

  const updateIsSurveyInsightsTab = (value) => {
    isSurveyInsightsTab.value = value;
  };

  const updateIsTableGenderData = (value) => {
    isTableGenderData.value = value;
  };

  const updateOptedToViewDataAnyway = (value) => {
    optedToViewDataAnyway.value = value;
  };

  // Actions
  const loadDemographicSurveyReport = async ({
    selectedDateRange,
    routeQuery,
    selectedFilters,
    selectedMyJobs,
  }) => {
    const lastQuarter = getQuarter(1);
    const fallbackDateRange = selectedDateRange || { from: format(lastQuarter[0], 'yyyy-MM-dd'), to: format(lastQuarter[1], 'yyyy-MM-dd') };
    const date = routeQuery?.from && routeQuery?.to
      ? { from: routeQuery.from, to: routeQuery.to }
      : fallbackDateRange;

    useAppLoading().setLoading({
      pageLoading: true, halfTopBarLoading: true,
    });

    const {
      genderBreakdown: newGenderBreakdown = {},
      raceBreakdown: newRaceBreakdown = {},
      genderByStage: newGenderByStage = {},
      raceByStage: newRaceByStage = {},
      sourceFillRate: newSourceFillRate = {},
    } = await getDemographicSurveyReport({
      selectedDateRange: date, routeQuery, filters: selectedFilters, selectedMyJobs,
    });

    updateOptedToViewDataAnyway(false);

    setData({
      newGenderBreakdown,
      newRaceBreakdown,
      newGenderByStage,
      newRaceByStage,
      newSourceFillRate,
    });

    useAppLoading().setLoading({
      pageLoading: false, halfTopBarLoading: false,
    });
  };

  const fetchFilters = async ({
    selectedDateRange,
    routeQuery,
    router,
    selectedFilters,
    selectedMyJobs,
  }) => {
    const routeStore = useAppRoute();
    const { route } = storeToRefs(routeStore);

    const lastQuarter = getQuarter(1);
    const fallbackDateRange = selectedDateRange || { from: format(lastQuarter[0], 'yyyy-MM-dd'), to: format(lastQuarter[1], 'yyyy-MM-dd') };
    const date = routeQuery?.from && routeQuery?.to
      ? { from: routeQuery.from, to: routeQuery.to }
      : fallbackDateRange;

    useAppLoading().setLoading({ filtersLoading: true });

    const filters = await getDemographicSurveyFilters({
      selectedDateRange: date, routeQuery, filters: selectedFilters, selectedMyJobs,
    });

    /* Send relevant updates to filters */
    useFilters().updateFilters({
      dateRange: filters.dateRange,
      filters: filters.filters,
      myJobsEnabled: filters.owned,
    });

    useAppLoading().setLoading({ filtersLoading: false });

    const prunedRootStateQuery = removeEmptyParams(route?.query);
    const prunedRouteQuery = removeEmptyParams(routeQuery);
    const dateAvailable = !prunedRootStateQuery?.to
          && !prunedRootStateQuery?.from
          && !prunedRouteQuery?.to
          && !prunedRouteQuery?.from
          && filters.dateRange?.from
          && filters.dateRange?.to;

    // Checks if the query changed or if there are selected date range and no query
    if (
      router
          && (!isEqual(prunedRootStateQuery, prunedRouteQuery)
            || !isEqual(prunedRouteQuery, {
              from: filters.dateRange.from,
              to: filters.dateRange.to,
            })
            || dateAvailable
          )
    ) {
      const { mergedTempFilters } = storeToRefs(useFilters());

      const newQuery = {
        ...prunedRouteQuery,
        ...mergedTempFilters.value,
        from: filters.dateRange?.from,
        to: filters.dateRange?.to,
      };

      if (filters?.owned?.value) {
        newQuery.owned = filters.owned.value;
      }

      router.push({
        query: newQuery,
      }).catch(() => true); // ignore duplicate navigation error due to global filter selection
    }
  };

  const fetchReqFillRate = async ({
    routeQuery, selectedDateRange, selectedFilters, selectedMyJobs,
  }) => {
    const { mergedTempFilters: filters } = storeToRefs(useFilters());
    const lastQuarter = getQuarter(1);
    const fallbackDateRange = selectedDateRange || { from: format(lastQuarter[0], 'yyyy-MM-dd'), to: format(lastQuarter[1], 'yyyy-MM-dd') };
    const date = routeQuery?.from && routeQuery?.to
      ? { from: routeQuery.from, to: routeQuery.to }
      : fallbackDateRange;

    reqFillRateLoading.value = true;

    try {
      const newReqFillRate = await loadReqFillRate({
        selectedDateRange: selectedDateRange || date,
        routeQuery,
        filters: selectedFilters || filters.value,
        sort: reqFillRateSort.value,
        page: 1,
        selectedMyJobs,
      });

      reqFillRate.value = newReqFillRate;
    } catch (error) {
      addAlert({ message: `Update req fill rate sort - ${error.message}`, thisPageOnly: true });
      throw error;
    } finally {
      reqFillRateLoading.value = false;
    }
  };

  const loadMoreFillRateRequisitions = async ({ routeQuery, page }) => {
    const { pages } = reqFillRate.value || {};

    if (typeof page !== 'number' || typeof pages !== 'number' || page > pages) return;

    reqFillRateLoadingMore.value = true;

    try {
      const { mergedTempFilters: filters } = storeToRefs(useFilters());
      const { dateRange } = storeToRefs(useDatePicker());

      const newReqFillRate = await loadReqFillRate({
        selectedDateRange: dateRange.value,
        routeQuery,
        filters: filters.value,
        page,
        sort: reqFillRateSort.value,
      });

      reqFillRate.value = {
        ...newReqFillRate,
        items: reqFillRate.value.items.concat(newReqFillRate.items),
        page,
        sort: reqFillRateSort.value,
      };
    } catch (error) {
      addAlert({ message: `Load more requisitions - ${error.message}`, thisPageOnly: true });
      throw error;
    } finally {
      reqFillRateLoadingMore.value = false;
    }
  };

  return {
    // state
    genderBreakdown,
    raceBreakdown,
    genderByStage,
    raceByStage,
    reqFillRate,
    sourceFillRate,
    reqFillRateLoadingMore,
    reqFillRateLoading,
    reqFillRateSort,
    isSurveyInsightsTab,
    isTableGenderData,
    optedToViewDataAnyway,
    // computed
    isEmptyData,
    genderAndRaceTableTotals,
    genderAndRaceTableRows,
    sortedSourceCategories,
    // mutations
    setData,
    updateReqFillRateSort,
    updateIsSurveyInsightsTab,
    updateIsTableGenderData,
    updateOptedToViewDataAnyway,
    // actions
    loadDemographicSurveyReport,
    loadMoreFillRateRequisitions,
    fetchReqFillRate,
    fetchFilters,
  };
});
